import React, { useEffect } from 'react'

const About = ({ data }) => {
  
  useEffect(() => {
    // Your condition or logic for redirection
    if(window.location.host.includes("app")) {
      window.location.href = ('/dashboard'); // Redirect to the desired route
     } 
}, []);
  return (
    <>
    
    </>
  )
}

export default About

const styles = {
  heroHeading: {
    marginBottom: '0.6em',
    fontSize: '1.5em',
    color: '#09467D',
  },
  card: {
    backgroundColor: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    margin: '8px 8px',
    padding: '30px 20px 100px 20px',
    borderRadius: '5px',
  },
}
